import SparkMD5 from "spark-md5";
import { minioUpload, merge } from "@/api/order";
/**
 * md5文件转化
 * @param {*} file 文件
 * @param {*} chunkSize 显示分片大小
 */
export async function getFileMd5(file, chunkSize) {
  return await new Promise((resolve) => {
    const spark = new SparkMD5.ArrayBuffer();
    const reader = new FileReader();
    const size = file.size; //文件大小
    const offset = chunkSize; // 分割大小，5M
    // 第一个5M，最后一个区块数据全要
    const chunks = [file.slice(0, offset)];
    let cur = offset;
    while (cur < size) {
      if (cur + offset >= size) {
        // 最后一个区快
        chunks.push(file.slice(cur, cur + offset));
      } else {
        // 中间的区块
        const mid = cur + offset / 2;
        const end = cur + offset;
        chunks.push(file.slice(cur, cur + 2));
        chunks.push(file.slice(mid, mid + 2));
        chunks.push(file.slice(end - 2, end));
      }
      cur += offset;
    }
    // 中间的，取前中后各2各字节
    reader.readAsArrayBuffer(new Blob(chunks));
    reader.onload = (e) => {
      spark.append(e?.target?.result);
      resolve(spark.end());
    };
  });
}
/**
 * 文件切片块
 */
export const createFileChunk = (file, hash, chunkSize) => {
  const fileChunkList = [];
  let cur = 0;
  let index = 0;
  const chunkCount = Math.ceil(file.size / chunkSize),
    fileSize = file.size;
  while (cur < file.size) {
    let start = index * chunkSize;
    let end = start + chunkSize;
    let packet = file.slice(start, end); //将文件进行切片
    fileChunkList.push({
      md5: hash,
      file: packet, //file.slice(cur, cur + chunkSize),
      name: file.name,
      totalSize: fileSize,
      total: chunkCount,
      index,
    });
    cur += chunkSize;
    index++;
  }
  return fileChunkList;
};
// 分片上传
export async function chunkFileList(file, fileMd5, chunkSize) {
  const fileObj = file;
  const fileChunkList = createFileChunk(fileObj, fileMd5, chunkSize);
  // 根据文件内容生成 hash 值
  const sharding = fileChunkList.map((item, index) => {
    // 转成promise
    const form = new FormData();
    form.append("md5", item.md5);
    form.append("file", item.file); //slice方法用于切出文件的一部分
    form.append("name", item.name);
    form.append("totalSize", item.totalSize);
    form.append("total", item.total); //总片数
    form.append("index", index + 1); //当前是第几片
    return { form, index: index, error: 0 };
  });
  //1.上传文件初始化 1存在文件   2续传  2全量上传
  let index = 0;
  const max = 1; // 设置浏览器运行最大并发数  目前6个为当前的主流
  const taskPool = [];
  while (index < sharding.length) {
    const requests = sharding[index];
    let form = requests.form;
    const fastDfs = minioUpload(form);
    fastDfs.then(() => {
      taskPool.splice(taskPool.findIndex((item) => item === fastDfs));
    });
    taskPool.push(fastDfs);
    if (taskPool.length === max) {
      await Promise.race(taskPool); // 竞赛等出一个执行完毕的请求
    }
    index++;
  }
  await Promise.all(taskPool);
  if (index == sharding.length) {
    return Promise.resolve({
      state: 1,
      chunkCount: sharding.length,
    });
  }
}
//合并分片文件
export async function mergeFile(
  chunkCount,
  fileName,
  fileMd5,
  fileSize,
  fileType,
  comparisonId
) {
  return await new Promise((resolve, reject) => {
    const postData = {
      shardCount: chunkCount,
      md5: fileMd5,
      fileName: fileName,
      fileType: fileType,
      fileSize: fileSize,
      id: comparisonId,
    };
    merge(postData).then((resp) => {
      if (resp.code == 0) {
        resolve(resp.data);
      } else {
        reject("未知错误");
      }
    });
  });
}
