<template>
  <el-dialog
    :title="title"
    :visible.sync="visible"
    :width="width"
    append-to-body
    :close-on-click-modal="false"
  >
    <!-- 列表 -->
    <div class="page-table">
      <el-table
        :data="exportList"
        stripe
        border
        height="500px"
        style="width: 100%"
        :header-cell-style="{ background: '#f5f7fa', color: '#606266' }"
        v-loading="tableLoading"
      >
        <template #empty>
          <p>{{ tableLoading == true ? "数据加载中" : "暂无数据" }}</p>
        </template>
        <el-table-column prop="createTime" label="导入时间" align="center" :width="160">
          <template slot-scope="scope">
            {{ formDateFormat(scope.row.createTime) }}
          </template>
        </el-table-column>

        <el-table-column prop="state" label="导入状态" align="center" width="100">
          <template slot-scope="scope">
            <span v-if="scope.row.state === 0">待生成</span>
            <span v-if="scope.row.state === 1" style="color: #f4b041">正在生成中</span>
            <span v-if="scope.row.state === 2" style="color: #0bce51">生成成功</span>
            <span v-if="scope.row.state === 3" style="color: #ec0b0b">生成失败</span>
          </template>
        </el-table-column>
        <el-table-column prop="resultMsg" label="导入结果">
          <template slot-scope="scope">
            <span
              :style="scope.row.state === 3 ? 'color: #ec0b0b' : ''"
              v-html="scope.row.resultMsg"
            ></span>
          </template>
        </el-table-column>
        <el-table-column
          prop="createBy"
          label="操作人"
          align="center"
          width="100"
        ></el-table-column>
        <el-table-column label="操作" align="center" width="140">
          <template slot-scope="scope">
            <el-button
              type="text"
              @click="clickDownloadReport(scope.row)"
              v-if="scope.row.state === 2"
              >下载比对报告</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 列表 -->
  </el-dialog>
</template>
<script>
import { exportReportTaskList } from "@/api/report";
import { dateFormat } from "@/utils/date";
import { downloadReportAsync } from "@/api/report";
import { downFiles } from "@/utils";
import { exportprocess } from "@/api/student";
export default {
  name: "exportInSchedule",
  props: {
    width: {
      type: String,
      default: "900px",
    },
    title: {
      type: String,
      default: "导入进度",
    },
    exportStudentButton: {
      type: Boolean,
      default: false,
    },
    download: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      visible: false,
      studentId: "",
      exportList: [],
      tableLoading: false,
      fullscreenLoading: false,
    };
  },
  methods: {
    formDateFormat(dateValue) {
      if (dateValue != null) {
        return dateFormat("YYYY-mm-dd HH:MM:SS", new Date(dateValue));
      }
    },
    taskExportprocess(taskNo, loads) {
      exportprocess(taskNo)
        .then((res) => {
          if (res.data.state == 0) {
            setTimeout(() => {
              this.taskExportprocess(taskNo, loads);
            }, 1000);
          } else if (res.data.state == 1) {
            loads.close();
            this.$notify({
              title: "成功",
              message: "打包下载成功",
              type: "success",
            });
            //下载文件
            downFiles(res.data.url, new Date().getTime() + ".rar");
          } else {
            loads.close();
            this.$notify({
              title: "警告",
              message: res.data.msg[0] || "异常错误",
              type: "warning",
              showClose: false,
            });
          }
        })
        .catch((err) => {
          console.info(err);
          loads.close();
          this.$notify({
            title: "警告",
            message: "文件比对报告下载失败，请重新下载",
            type: "warning",
            showClose: false,
          });
        });
    },
    //下载比对报告
    async clickDownloadReport(record) {
      const loading = this.$loading({
        lock: true,
        text: "数据导出中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.4)",
      });
      try {
        const resp = await downloadReportAsync(record.id);
        if (resp.code == 0) {
          this.taskExportprocess(resp.data.taskNo, loading);
        }
      } catch (error) {
        loading.close();
      }
    },
    async initData(studentId) {
      const that = this;
      that.visible = true;
      that.studentId = studentId;
      this.tableLoading = true;
      const resp = await exportReportTaskList(studentId);
      this.tableLoading = false;
      if (resp.code == 0) {
        this.exportList = resp.data;
      }
    },
  },
};
</script>
