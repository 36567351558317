<template>
  <el-dialog
    :title="title"
    :visible.sync="visible"
    :width="width"
    append-to-body
    :close-on-click-modal="false"
  >
    <!-- 搜索 -->
    <div class="search-box">
      <div class="condition-item">
        <label class="fn-14">订单号：</label>
        <el-input
          v-model="searchObj.orderNo"
          placeholder="请输入订单号"
          autocomplete="off"
          size="small"
        ></el-input>
      </div>
      <div class="condition-item">
        <label class="fn-14">学号：</label>
        <el-input
          v-model="searchObj.studentNo"
          placeholder="请输入学生学号"
          autocomplete="off"
          size="small"
        ></el-input>
      </div>
      <div class="condition-item">
        <el-button type="primary" icon="el-icon-search" @click="handleSearch" size="small"
          >查询
        </el-button>
      </div>
    </div>
    <!-- 搜索 -->
    <!-- 列表 -->
    <div class="page-table">
      <el-table
        :data="studentList"
        stripe
        border
        height="500px"
        style="width: 100%"
        :header-cell-style="{ background: '#f5f7fa', color: '#606266' }"
        v-loading="tableLoading"
      >
        <template #empty>
          <p>{{ tableLoading == true ? "数据加载中" : "暂无数据" }}</p>
        </template>
        <el-table-column prop="orderNo" label="订单号" width="220"></el-table-column>
        <el-table-column prop="name" label="姓名" width="100"></el-table-column>
        <el-table-column prop="studentCode" label="学号" width="100"></el-table-column>
        <el-table-column prop="cc" label="学历层次" width="100"></el-table-column>
        <el-table-column prop="idCard" label="证件号码" width="160"></el-table-column>
        <el-table-column prop="schoolCode" label="院校代码" width="90"></el-table-column>
        <el-table-column prop="classes" label="班级" width="130"></el-table-column>
        <el-table-column prop="major" label="专业" width="130"></el-table-column>
        <el-table-column prop="fy" label="分院" width="100"></el-table-column>
        <el-table-column
          prop="photoToPhotoScore"
          label="录取照片&入学采集照片比对评分"
          width="230"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="photoToPhotoResult"
          label="录取照片&入学采集照片"
          width="200"
        ></el-table-column>
        <el-table-column
          prop="photoToNation"
          label="录取照片&国家人口库照片"
          width="200"
        ></el-table-column>
        <el-table-column
          prop="newToNation"
          label="入学采集照片&国家人口库照片"
          width="220"
        ></el-table-column>
      </el-table>
    </div>
    <!-- 列表 -->
    <!-- 分页 -->
    <div style="display: flex; justify-content: right" class="mg-top-10">
      <el-pagination
        @size-change="pageConfig.handleSizeChange"
        @current-change="pageConfig.handleCurrentChange"
        :current-page="pageConfig.currentPage"
        :page-sizes="pageConfig.limits"
        :page-size="pageConfig.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pageConfig.total"
      />
    </div>
    <!-- 分页 -->
  </el-dialog>
</template>
<script>
import { queryReprotdataByTaskId } from "@/api/report";

export default {
  name: "compareDetails",
  props: {
    width: {
      type: String,
      default: "1100px",
    },
    title: {
      type: String,
      default: "查看对比详情",
    },
  },
  data() {
    return {
      visible: false,
      studentId: "",
      studentList: [],
      searchObj: {
        orderNo: "",
        studentNo: "",
      },
      tableLoading: false,
      pageConfig: {
        currentPage: 1,
        pageSize: 20,
        total: 0,
        limits: [10, 20, 30, 40, 50, 100],
        //显示分布条数
        handleSizeChange(val) {
          this.pageConfig.pageSize = val;
          this.execFrom();
        },
        //选择分页
        handleCurrentChange(val) {
          this.pageConfig.currentPage = val;
          this.execFrom();
        },
      },
    };
  },
  methods: {
    //查询
    handleSearch() {
      this.pageConfig.currentPage = 1;
      this.funcQueryReprotdataByTaskId();
    },
    async funcQueryReprotdataByTaskId() {
      const { currentPage, pageSize } = this.pageConfig;
      this.tableLoading = true;
      this.searchObj.taskId = this.studentId;
      try {
        const result = await queryReprotdataByTaskId(
          currentPage,
          pageSize,
          this.searchObj
        );
        this.tableLoading = false;
        if (result.code == 0) {
          this.studentList = result.data.content;
          this.pageConfig.total = result.data.totalElements;
        }
      } catch {
        this.tableLoading = false;
      }
    },
    initData(studentId) {
      const that = this;
      that.visible = true;
      that.studentId = studentId;
      this.funcQueryReprotdataByTaskId();
    },
  },
};
</script>
