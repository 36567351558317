<template>
  <el-dialog
    :title="title"
    :visible.sync="visible"
    :width="width"
    append-to-body
    :close-on-click-modal="false"
  >
    <!-- 列表 -->
    <div class="page-table">
      <el-table
        :data="exportList"
        stripe
        border
        height="500px"
        style="width: 100%"
        :header-cell-style="{ background: '#f5f7fa', color: '#606266' }"
        v-loading="tableLoading"
      >
        <template #empty>
          <p>{{ tableLoading == true ? "数据加载中" : "暂无数据" }}</p>
        </template>
        <el-table-column prop="fileName" label="文件名称"></el-table-column>
        <el-table-column prop="createTime" label="导入时间" align="center" :width="160">
          <template slot-scope="scope">
            {{ formDateFormat(scope.row.createTime) }}
          </template>
        </el-table-column>
        <el-table-column prop="type" label="导入类型" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.type === 1">刷卡信息</span>
            <span v-if="scope.row.type === 2">录取照</span>
            <span v-if="scope.row.type === 3">比对报告</span>
          </template>
        </el-table-column>
        <el-table-column prop="state" label="导入状态" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.state === 1">导入中</span>
            <span v-if="scope.row.state === 2" style="color: #0bce51">导入完成</span>
            <span v-if="scope.row.state === 3" style="color: #ec0b0b">导入失败</span>
          </template>
        </el-table-column>
        <el-table-column prop="resultMsg" label="导入结果">
          <template slot-scope="scope">
            <span
              :style="scope.row.state === 3 ? 'color: #ec0b0b' : ''"
              v-html="scope.row.resultMsg"
            ></span>
          </template>
        </el-table-column>
        <el-table-column prop="createBy" label="操作人" align="center"></el-table-column>
      </el-table>
    </div>
    <!-- 列表 -->
  </el-dialog>
</template>
<script>
import { importUploadList } from "@/api/report";
import { dateFormat } from "@/utils/date";

export default {
  name: "exportInSchedule",
  props: {
    width: {
      type: String,
      default: "900px",
    },
    title: {
      type: String,
      default: "导入进度",
    },
    exportStudentButton: {
      type: Boolean,
      default: false,
    },
    download: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      visible: false,
      studentId: "",
      exportList: [],
      tableLoading: false,
    };
  },
  methods: {
    formDateFormat(dateValue) {
      if (dateValue != null) {
        return dateFormat("YYYY-mm-dd HH:MM:SS", new Date(dateValue));
      }
    },
    async initData(studentId) {
      const that = this;
      that.visible = true;
      that.studentId = studentId;
      this.tableLoading = true;
      const resp = await importUploadList(studentId);
      this.tableLoading = false;
      if (resp.code == 0) {
        this.exportList = resp.data;
      }
    },
  },
};
</script>
