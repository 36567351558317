<template>
  <el-dialog
    :title="title"
    :visible.sync="visible"
    :width="width"
    append-to-body
    :close-on-click-modal="false"
  >
    <!-- 搜索 -->
    <div class="search-box">
      <div class="condition-item">
        <label class="fn-14">订单号：</label>
        <el-input
          v-model="searchObj.orderNo"
          placeholder="请输入订单号"
          autocomplete="off"
          size="small"
        ></el-input>
      </div>
      <div class="condition-item">
        <label class="fn-14">学号：</label>
        <el-input
          v-model="searchObj.studentCode"
          placeholder="请输入学生学号"
          autocomplete="off"
          size="small"
        ></el-input>
      </div>
      <div class="condition-item">
        <el-button type="primary" icon="el-icon-search" @click="handleSearch" size="small"
          >查询
        </el-button>
        <el-button
          type="primary"
          icon="el-icon-download"
          @click="handleExport('card')"
          v-if="exportStudentButton"
          size="small"
          :disabled="exportState"
          :loading="exportState"
          >导出未上传刷卡学生信息
        </el-button>
        <el-button
          type="primary"
          icon="el-icon-download"
          @click="handleExport('photo')"
          v-if="exportInStudentButton"
          :disabled="exportState"
          :loading="exportState"
          size="small"
          >导出未上传入学照学生
        </el-button>
      </div>
    </div>
    <!-- 搜索 -->
    <!-- 列表 -->
    <div class="page-table">
      <el-table
        :data="studentList"
        stripe
        border
        height="500px"
        style="width: 100%"
        :header-cell-style="{ background: '#f5f7fa', color: '#606266' }"
        v-loading="tableLoading"
      >
        <template #empty>
          <p>{{ tableLoading == true ? "数据加载中" : "暂无数据" }}</p>
        </template>
        <el-table-column prop="orderNo" label="订单号" width="180"></el-table-column>
        <el-table-column prop="studentName" label="姓名" width="100"></el-table-column>
        <el-table-column prop="studentCode" label="学号" width="100"></el-table-column>
        <el-table-column
          prop="educationType"
          label="学历层次"
          width="100"
        ></el-table-column>
        <el-table-column prop="idNumber" label="证件号码" width="160"></el-table-column>
        <el-table-column prop="schoolCode" label="院校代码" width="90"></el-table-column>
        <el-table-column prop="classes" label="班级" width="130"></el-table-column>
        <el-table-column prop="major" label="专业" width="130"></el-table-column>
        <el-table-column prop="fy" label="分院" width="100"></el-table-column>
        <el-table-column prop="collectedTime" label="采集时间" width="160">
          <template slot-scope="scope">
            {{ formDateFormat(scope.row.collectedTime) }}
          </template>
        </el-table-column>
        <el-table-column prop="status" label="状态" width="100" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.orderStatus === 0">下单</span>
            <span v-if="scope.row.orderStatus === 1">预付款</span>
            <span v-if="scope.row.orderStatus === 2">完成</span>
            <span v-if="scope.row.orderStatus === 3">取消</span>
            <span v-if="scope.row.orderStatus === 5">取消中</span>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 列表 -->
    <!-- 分页 -->
    <div style="display: flex; justify-content: right" class="mg-top-10">
      <el-pagination
        @size-change="pageConfig.handleSizeChange"
        @current-change="pageConfig.handleCurrentChange"
        :current-page="pageConfig.currentPage"
        :page-sizes="pageConfig.limits"
        :page-size="pageConfig.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pageConfig.total"
      />
    </div>
    <!-- 分页 -->
  </el-dialog>
</template>
<script>
import { queryEducationalsDetailByTaskId } from "@/api/report";
import { dateFormat } from "@/utils/date";
import { getToken } from "@/utils/auth";

import axios from "axios";

export default {
  name: "studentInfoCompare",
  props: {
    width: {
      type: String,
      default: "1100px",
    },
    title: {
      type: String,
      default: "学生信息",
    },
    exportStudentButton: {
      type: Boolean,
      default: false,
    },
    exportInStudentButton: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      visible: false,
      exportState: false,
      studentId: "",
      studentList: [],
      searchObj: {
        orderNo: "",
        studentCode: "",
      },
      tableLoading: false,
      pageConfig: {
        currentPage: 1,
        pageSize: 20,
        total: 0,
        limits: [10, 20, 30, 40, 50, 100],
        //显示分布条数
        handleSizeChange(val) {
          this.pageConfig.pageSize = val;
          this.execFrom();
        },
        //选择分页
        handleCurrentChange(val) {
          this.pageConfig.currentPage = val;
          this.execFrom();
        },
      },
    };
  },
  methods: {
    formDateFormat(dateValue) {
      if (dateValue != null) {
        return dateFormat("YYYY-mm-dd HH:MM:SS", new Date(dateValue));
      }
    },
    //查询
    handleSearch() {
      this.pageConfig.currentPage = 1;
      this.funcQueryEducationalsDetailByTaskId();
    },
    async funcQueryEducationalsDetailByTaskId() {
      const { currentPage, pageSize } = this.pageConfig;
      this.tableLoading = true;
      this.searchObj.taskId = this.studentId;
      try {
        const result = await queryEducationalsDetailByTaskId(
          currentPage,
          pageSize,
          this.searchObj
        );
        this.tableLoading = false;
        if (result.code == 0) {
          this.studentList = result.data.content;
          this.pageConfig.total = result.data.totalElements;
        }
      } catch {
        this.tableLoading = false;
      }
    },
    //导出未上传刷卡学生信息
    async handleExport(mod = "card") {
      let url = "";
      this.exportState = true;
      if (mod == "card") {
        url = "/api/csias/file/exportNotUploadCardData/" + this.studentId;
      } else if (mod == "photo") {
        url = "/api/csias/file/exportNotUploadPhotoData/" + this.studentId;
      }
      this.exportExcel(url);
    },
    exportExcel(url) {
      this.tableLoading = true;
      axios({
        method: "GET",
        url: url,
        headers: {
          Authorization: "Bearer " + getToken(),
          "Content-Type": "application/json",
        },
        responseType: "blob",
        timeout: 1200000,
      }).then(
        (response) => {
          if (response.status === 200 && response.data) {
            console.log(response.data);
            let blob = new Blob([response.data], {
              type: "application/octet-stream",
            });

            let fileName = Date.parse(new Date()) + ".xlsx";
            if (window.navigator.msSaveOrOpenBlob) {
              navigator.msSaveBlob(blob, fileName);
            } else {
              var link = document.createElement("a");
              link.href = window.URL.createObjectURL(blob);
              link.download = fileName;
              link.click();
              //释放内存
              window.URL.revokeObjectURL(link.href);
            }
            this.$notify.success({
              title: "成功",
              message: "下载文件成功！",
            });
          } else {
            this.$notify.error({
              title: "错误",
              message: "下载失败，未查询到数据",
            });
          }
          this.tableLoading = false;
          this.exportState = false;
        },
        () => {
          this.tableLoading = false;
          this.exportState = false;
          this.$notify.error({
            title: "错误",
            message: "下载失败，未查询到数据",
          });
        }
      );
    },
    initData(studentId) {
      const that = this;
      that.visible = true;
      that.studentId = studentId;
      this.funcQueryEducationalsDetailByTaskId();
    },
  },
};
</script>
