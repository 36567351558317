<template>
  <div class="search-box">
    <div class="condition-item">
      <label class="fn-14">任务名称</label>
      <el-input
        v-model="searchObj.title"
        placeholder="请输入任务名称"
        autocomplete="off"
        size="small"
      ></el-input>
    </div>
    <div class="condition-item">
      <label class="fn-14">学校名称</label>
      <el-input
        v-model="searchObj.schoolName"
        placeholder="请输入学校名称"
        autocomplete="off"
        size="small"
      ></el-input>
    </div>
    <div class="condition-item">
      <label class="fn-14">采集时间</label>
      <el-date-picker
        v-model="searchObj.stime"
        type="date"
        format="yyyy 年 MM 月 dd 日"
        value-format="yyyy-MM-dd"
        placeholder="选择开始时间"
        autocomplete="off"
        size="small"
        style="width: 160px"
      >
      </el-date-picker>
      <label class="fn-14" style="padding-left: 10px">-</label>
      <el-date-picker
        v-model="searchObj.etime"
        type="date"
        format="yyyy 年 MM 月 dd 日"
        value-format="yyyy-MM-dd"
        placeholder="选择结束时间"
        autocomplete="off"
        size="small"
        style="width: 160px"
      >
      </el-date-picker>
    </div>
    <div class="condition-item">
      <el-button type="primary" icon="el-icon-search" @click="handleSearch" size="small"
        >查询
      </el-button>
    </div>
  </div>
</template>
<script>
export default {
  name: "compareQueryFrom",
  components: {},
  data() {
    return {
      searchObj: {
        title: "", //任务标题
        schoolName: "", //学校名称
        stime: "", //采集时间-开始时间
        etime: "", //采集时间-结束时间
      },
    };
  },
  methods: {
    //搜索并返回结果送回父组件
    handleSearch() {
      const search = this.searchObj;
      this.$emit("funcSearchResult", search);
    },
  },
};
</script>
